export const theme = {
	primaryDark: "#616A81",
	secondaryDark1: "#556673",
	secondaryDark2: "#7C6E7E",
	primaryLight: "#B4768A",
	secondaryLight: "#DE8382",
	background1: "#C1C7CC",
	background2: "#E3CDD4",
	background3: "#f5f5f5",
	background4: "#f6f9fc",
	gold2: "#d99058",
	logoA: "#7c6e7e",
	logoTele: "#515b75",
	mauve: "#B4768A",
	modalOverlay: "#151515",
	darker: "#252629",
	darkBlue: "#252A36",
	darkBlue2: "#1F2D4F",
	darkBlue3: "#141323",
	cream: "#e6e6d8",
	franNavy: "#2B3757",
	franRed: "#9B4862",
	franGreen: "#1C3343",
	franPurple: "#4F3D53",
	franPink: "#D35958",
	moonCard: "#232526",
	headerBackground: "#2F3334",
	fontLight: "#EBE630",
	fontDark: "#2F3334",
	cardLight: "#BDC2C6",
	cardDark: "#cecece",
	starLight: "#FAE6BF",
	starDark: "#2F3334",
	bodyBackground: "#2F3334",
	clickable: "#FAE6BF",
	colorGood: "#99C192",
	colorBad: "#BB6F6F",
	colorMedium: "#DAA979",
	lightDark: "#5A5A5A",
	prettyDark: "#111414",
	prettyDarkish:"#111414d1",
	moreInfoBackground: "#E4E4E4",
	parkMapGreen: "#174030",
	lightBlue: "#485261",
	mapBlue: "#2d333c",
	lightDarkish: "#4e4e4e",
	moonBackground: "#242627",
	darkAccent: "#1b1c1d",
	white:"#dadada",
	colorBadHover: "#d47b7b",
	colorMediumHover: "#ecbc8e",
	cardDarkHover: "#c2c8ce",
	cardLightHover: "#c2ccd4",
	yellow: "#edd9c0",
	cardHeader: "#8C969C",
	cardHeaderHover:"#707a88",
	directionsHover:"#384771",
	pink:"#DBCCC5",
	green: "#8C969C",
	highlightPink:"#bbabba"
};
